<template>
  <div>
    <b-table-simple bordered responsive class="mt-2">
      <b-thead>
        <b-tr>
          <b-th>
            {{
              $getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              )
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <MethodologicalStrategiesCell
              :ProfileMatter="ProfileMatter"
              :allows_crud="allows_crud"
              class="mt-3 ml-2"
            ></MethodologicalStrategiesCell>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";

export default {
  name: "MethodologicalStrategyTable",
  components: {
    MethodologicalStrategiesCell: () =>
      import("@/components/mesh/Matrix2/MethodologicalStrategiesCell"),
  },
  props: {
    ProfileMatter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({
    // }),
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black !important;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black !important;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
@media print {
  .bibliography-content,
  .support-resource-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 800px !important;
  }
}
</style>